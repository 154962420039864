<template>
  <div class="container">
    <div class="banner" :style="{backgroundImage: bannerImg}">
      <p>服务与技术支持</p>
      <span>专业的高效服务，我们心里装着客户与伙伴的需求，并竭尽所能！</span>
      <div class="arrow">
        <i class="iconfont icon-downArrow"></i>
      </div>
    </div>
    <div class="support">
      <div class="support-moblie section">
        <h2 data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">服务电话</h2>
        <div class="moblie-num" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">
          <img src="../../static/support-moblie.png" />
          <p v-if="isMobile">
            电话：<a href="tel: sales@jiangsutimi.com">sales@jiangsutimi.com</a>
          </p>
          <p v-else>电话：sales@jiangsutimi.com</p>
        </div>
        <span class="moblie-time" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">周一至周五
          8：30—18：00</span>
        <div class="info" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">
          <span class="moblie-content">我们为客户提供多样化、智能化解决方案及产品的同时，</span>
          <span class="moblie-content">始终为客户提供优质、专业的服务支持。欢迎各界朋友的监督与指导。</span>
        </div>
        <a class="customer-channel" href="http://jstimi.com/TMOA" target="_blank">客户通道</a>
      </div>
      <div class="sales section" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
        <h2>销售专线</h2>
        <div class="salesCnt" :class="salesData.length>6?'exceed':''" @wheel="handleScroll">
          <template v-for="(item,index) in salesData">
            <div v-if="(isMobile && index<mobileNum) || !isMobile" class="salesItem" :key="index">
              <p>{{item.title}}</p>
              <div class="row">
                <i class="iconfont icon-telephone"></i>
                <span>{{item.phoneName}}：</span>
                <a v-if="isMobile" :href="'tel:' + item.phone">{{ item.phone }}</a>
                <span v-else>{{ item.phone }}</span>
              </div>
              <div class="row">
                <i class="iconfont icon-telephone"></i>
                <span>{{item.phoneName2}}：</span>
                <a v-if="isMobile" :href="'tel:' + item.phone2">{{ item.phone2 }}</a>
                <span v-else>{{ item.phone2 }}</span>
              </div>
              <div class="row">
                <i class="iconfont icon-consult"></i>
                <span>{{item.imgName}}</span>
                <el-image :src="item.imgUrl" fit="contain"></el-image>
              </div>
            </div>
          </template>
        </div>
        <el-button v-if="isMobile && salesData.length>4" type="primary" @click="showMore">{{salesBtnName}}</el-button>
      </div>
      <div class="support-feedback section" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
        <h2>合作意向</h2>
        <el-form label-width="0" :inline="true" :model="feedback" ref="feedbackRef" :rules="rules"
          class="feedback-form">
          <el-form-item v-if="!isMobile" label="" class="form-item" prop="title">
            <el-input class="title" v-model="feedback.title" placeholder="合作意向简述"></el-input>
          </el-form-item>
          <el-form-item class="form-item" label="" prop="name">
            <el-input class="name" v-model="feedback.name" placeholder="您的姓名"></el-input>
          </el-form-item>
          <el-form-item class="form-item" label="" prop="mobile">
            <el-input type="tel" class="mobile" v-model="feedback.mobile" placeholder="您的联系方式"></el-input>
          </el-form-item>
          <el-form-item v-if="isMobile" label="" class="form-item" prop="title">
            <el-input class="title" v-model="feedback.title" placeholder="合作意向简述"></el-input>
          </el-form-item>
          <el-form-item class="form-item" label="" prop="content">
            <el-input type="textarea" v-model="feedback.content" placeholder="合作内容描述"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        bannerImg: '',
        isMobile: false,
        salesData: [],
        mobileNum: 4,
        salesBtnName: "更多",
        feedback: {
          title: "",
          name: "",
          mobile: "",
          content: ""
        },
        rules: {
          title: [{
            required: true,
            message: "请输入合作意向简述",
            trigger: "blur"
          }],
          name: [{
            required: true,
            message: "请输入您的姓名",
            trigger: "blur"
          }],
          mobile: [{
              required: true,
              message: "请输入您的联系方式",
              trigger: "blur"
            },
            {
              pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
              message: "请输入正确的手机号码或者座机"
            }
          ],
          content: [{
            required: true,
            message: "请输入合作内容描述",
            trigger: "blur"
          }],
        },
      };
    },
    mounted() {
      sessionStorage.setItem("pageName", "Support");
      this.bus.$emit('curPage', 5);
      sessionStorage.setItem('page', 5);
      this.isMobile = localStorage.getItem("ISMOBILE") === "Y" ? true : false;
      if (this.isMobile) {
        this.bannerImg = `url(${process.env.BASE_URL}images/banner_support_phone.png)`;
      } else {
        this.bannerImg = `url(${process.env.BASE_URL}images/banner_support.png)`;
      }
      this.getData();
    },
    beforeDestroy() {
      this.bus.$off("ISMOBILE");
    },
    methods: {
      getData() {
        if (typeof([
  {
    "_X_ROW_KEY": "row_38",
    "title": "智慧新能源",
    "phoneName": "分布式光伏",
    "imgUrl": "./website/public/images/491040800346181.png?t=491040822652997",
    "imgName": "在线咨询",
    "phone": "集中式光伏",
    "phoneName2": "海上光伏",
    "phone2": "海上风电"
  },
  {
    "_X_ROW_KEY": "row_47",
    "title": "智慧火电",
    "imgUrl": "./website/public/images/490649115967557.png?t=490649116074053",
    "imgName": "在线咨询",
    "phoneName": "燃煤电厂",
    "phone": "供热电厂",
    "phoneName2": "垃圾电厂",
    "phone2": "电力设计院"
  },
  {
    "_X_ROW_KEY": "row_56",
    "title": "电网数智化",
    "imgUrl": "./website/public/images/491040829714501.png?t=491040846835781",
    "imgName": "在线咨询",
    "phoneName": "变电站",
    "phone": "输电线路",
    "phoneName2": "新型电力系统",
    "phone2": "配售电"
  }
]) == "object") {
          const data = [
  {
    "_X_ROW_KEY": "row_38",
    "title": "智慧新能源",
    "phoneName": "分布式光伏",
    "imgUrl": "./website/public/images/491040800346181.png?t=491040822652997",
    "imgName": "在线咨询",
    "phone": "集中式光伏",
    "phoneName2": "海上光伏",
    "phone2": "海上风电"
  },
  {
    "_X_ROW_KEY": "row_47",
    "title": "智慧火电",
    "imgUrl": "./website/public/images/490649115967557.png?t=490649116074053",
    "imgName": "在线咨询",
    "phoneName": "燃煤电厂",
    "phone": "供热电厂",
    "phoneName2": "垃圾电厂",
    "phone2": "电力设计院"
  },
  {
    "_X_ROW_KEY": "row_56",
    "title": "电网数智化",
    "imgUrl": "./website/public/images/491040829714501.png?t=491040846835781",
    "imgName": "在线咨询",
    "phoneName": "变电站",
    "phone": "输电线路",
    "phoneName2": "新型电力系统",
    "phone2": "配售电"
  }
];
          data.forEach(item => {
            if (item.imgUrl && item.imgUrl.indexOf("/public/") > 0) {
              item.imgUrl = process.env.BASE_URL + item.imgUrl.replace("./website/public/", "");
            }
            this.salesData.push(item);
          })
        } else {
          for (let i = 0; i < 7; i++) {
            this.salesData.push({
              title: '业务板块',
              phoneName: '测试号码',
              phone: '13245295000',
              phoneName2: '测试号码',
              phone2: '13245295000',
              imgName: '图片名称',
              imgUrl: ''
            })
          }
        }
      },
      submit() {
        this.$refs.feedbackRef.validate((isok) => {
          if (isok) {
            axios({
                method: 'POST',
                url: 'https://jstimi.com:8443/Web/Opinion?action=SubmitOpinion',
                data: {
                  name: this.feedback.name,
                  phone: this.feedback.mobile,
                  topic: this.feedback.title,
                  content: this.feedback.content
                }
              })
              .then((response) => {
                this.$refs.feedbackRef.resetFields();
                this.$message({
                  type: 'success',
                  message: '提交成功！'
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      },
      showMore() {
        if (this.salesBtnName === "更多") {
          this.salesBtnName = "收起";
          this.mobileNum = this.salesData.length;
        } else {
          this.salesBtnName = "更多";
          this.mobileNum = 4;
        }
      },
      handleScroll(event) {
        if (this.salesData.length > 6) {
          event.preventDefault();
          const salesCnt = document.querySelector(".salesCnt");
          const max = salesCnt.scrollWidth;
          const min = 0;
          if (event.wheelDelta < 0 && salesCnt.scrollLeft < max) {
            salesCnt.scrollLeft += 50;
            if (salesCnt.scrollLeft >= max) salesCnt.scrollLeft = max;
          }
          if (event.wheelDelta > 0 && salesCnt.scrollLeft > min) {
            salesCnt.scrollLeft -= 50;
            if (salesCnt.scrollLeft <= min) salesCnt.scrollLeft = min;
          }
        }
      }
    }
  };
</script>

<style scoped lang='less'>
  .support {
    padding: 0 285px 80px 285px;

    .support-moblie {
      flex-direction: column;
      align-items: center;

      .moblie-num {
        display: flex;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 400;
        color: #333;

        img {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }

        a {
          text-decoration: none;
          color: #333;
        }
      }

      .moblie-time {
        font-size: 16px;
        font-weight: 400;
        color: #333;
        margin-bottom: 20px;
      }

      .moblie-content {
        font-size: 18px;
        font-weight: 400;
        color: #333;
      }

      .customer-channel {
        width: 264px;
        height: 60px;
        background: #0066ff;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 60px;
        text-decoration: none;
        text-align: center;
        margin-top: 30px;
      }
    }

    /* 销售专线 */
    .sales {
      .salesCnt {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-height: 800px;
        padding-bottom: 30px;
        margin-top: 40px;
        overflow-x: auto;
        overflow-y: hidden;

        &.exceed {
          flex-direction: column;
        }

        .salesItem {
          flex-shrink: 0;
          width: 332px;
          height: 292px;
          padding: 24px;
          margin: 20px 35px;
          background: #FFF;
          border-radius: 12px;
          box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.08);

          p {
            font-size: 24px;
            margin-bottom: 12px;
          }

          .row {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #666;
            line-height: 40px;

            .iconfont {
              margin-right: 15px;
            }

            .el-image {
              width: 150px;
              height: 150px;
              margin-top: 5px;
              margin-left: 25px;
            }

            &:last-child {
              align-items: flex-start;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 10px;
          /* 这是针对缺省样式 (必须的) */
        }

        &::-webkit-scrollbar-track {
          background-color: #EAF3FC;
          /*  滚动条的滑轨背景颜色 */
        }

        &::-webkit-scrollbar-thumb {
          background-color: #005CE5;
          /* 滑块颜色 */
          border-radius: 0;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #4690FF;
          /* 滑块高亮*/
        }

        &::-webkit-scrollbar-button {
          display: none;
          /* 滑轨两头的监听按钮 */
        }

        &::-webkit-scrollbar-corner {
          display: none;
          /* 横向滚动条和纵向滚动条相交处尖角 */
        }
      }

      .el-button {
        background-color: #0066ff;
      }
    }

    /* 合作意向 */
    .support-feedback {
      flex-direction: column;
      align-items: center;
      padding: 0 30px;

      .feedback-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 60px;

        .el-input {
          width: 365px;
          height: 50px;

          &.title {
            width: 500px;
          }

          ::v-deep .el-input__inner {
            height: 50px;
            background: #f8f8f8;
            border: none;
            font-size: 16px;
            color: #000;
            border-radius: 0;
            border-left: 3px solid transparent;

            &::placeholder {
              font-size: 14px;
              font-weight: bold;
              color: #333;
              opacity: 0.6;
            }

            &:focus {
              background: rgba(0, 102, 255, 0.05);
              border-left: 3px solid #0066ff;
            }
          }
        }

        .el-textarea {
          width: 1290px;
          margin-top: 16px;

          ::v-deep .el-textarea__inner {
            height: 240px;
            background: #f8f8f8;
            border: none;
            font-size: 16px;
            color: #000;
            border-radius: 0;

            &::placeholder {
              font-size: 14px;
              font-weight: bold;
              color: #333;
              opacity: 0.6;
            }

            &:focus {
              background: rgba(0, 102, 255, 0.05);
            }
          }
        }

        .el-form-item {
          margin-right: 0;

          &:last-child {
            margin-top: 20px;
            width: 100%;
            text-align: center;

            .el-button {
              width: 264px;
              height: 60px;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              background-color: #0066ff;
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  // 移动端样式
  @media only screen and (max-width: 1023px) {
    .support {
      padding: 0 15px;

      .support-moblie {
        .moblie-num {
          font-size: 22px;

          a {
            color: #0066ff;
          }
        }

        .moblie-time {
          font-size: 20px;
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-size: 20px;
            margin: 2px 0;
          }
        }

        .customer-channel {
          display: none;
        }
      }

      /* 销售专线 */
      .sales {
        .salesCnt {
          justify-content: space-between;
          max-height: unset;
          overflow: hidden;

          &.exceed {
            flex-direction: row;
          }

          .salesItem {
            width: 308px;
            height: 292px;
            padding: 15px;
            margin: 10px;

            p {
              font-size: 26px;
            }

            .row {
              font-size: 22px;
              line-height: 45px;

              .iconfont {
                font-size: 22px;
                margin-right: 10px;
              }
            }
          }
        }

        .el-button {
          width: 120px;
          height: 50px;
          font-size: 18px;
          background-color: #0066ff;
        }
      }

      .support-feedback {
        .feedback-form {
          .el-input {
            width: 320px;

            &.title {
              width: 660px;
            }
          }

          .el-textarea {
            width: 660px;
            margin-top: 0;
          }
        }
      }
    }
  }
</style>
