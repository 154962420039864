<template>
  <div class="footer">
    <div class="left">
      <h4>江苏提米智能科技有限公司</h4>
      <p>JiangSu Timi Smart Technology Co., Ltd.</p>
      <div class="divider"></div>
      <p class="pc"><i class="iconfont icon-telephone"></i>电话：18761878780</p>
      <p class="phone">
        <i class="iconfont icon-telephone"></i>电话：<a href="tel:18761878780">18761878780</a>
      </p>
      <p><i class="iconfont icon-address"></i>地址：南京市江北新区星火路14号长峰大厦3层</p>
      <p>
        <i class="iconfont icon-email"></i>销售：<a href="mailto:sales@jiangsutimi.com">sales@jiangsutimi.com</a>
      </p>
      <p>
        <i class="iconfont icon-email"></i>市场：<a href="mailto:markting@jiangsutimi.com">markting@jiangsutimi.com</a>
      </p>
    </div>
    <div class="right">
      <p>关注我们</p>
      <div class="item">
        <el-image :src="`${publicPath}images/weChatCode.png`" fit="contain"></el-image>
        <span>官方公众号</span>
      </div>
      <div class="item">
        <el-image :src="`${publicPath}images/videoCode.png`" fit="contain"></el-image>
        <span>官方视频号</span>
      </div>
    </div>
    <div class="bottom">
      <span>版权所有 © {{ year }} 江苏提米智能科技有限公司</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"><span>苏ICP备18044499号-1</span></a>
      <span class="pointer" @click="changePage('/PrivacyPolicy')">隐私政策</span>
      <span class="pointer map" @click="changePage('/Sitemap')">网站地图</span>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "FooterDiv",
    data() {
      return {
        publicPath: process.env.BASE_URL,
        year: 0,
        phone: "",
        email: "",
      };
    },
    created() {
      this.year = new Date().getFullYear();
      this.getData();
    },
    methods: {
      changePage(path) {
        if (this.$route.path !== path) {
          this.$router.push(path);
        }
        sessionStorage.setItem("page", 0);
        this.bus.$emit("curPage", 0);
      },
      getData(STATICDATA) {
STATICDATA =  [{"YXDHPZ_LXBH":"DBQY","YXDHPZ_LXTEL":"18761878780","YXDHPZ_EMAIL":"timi@jiangsutimi.com"}];
        const _this = this;
        if (STATICDATA) {
          this.initData(STATICDATA);
        } else {
          this.myAxios({
            action: "GetDBQYTEL",
            data: {},
            success: function(data) {
              _this.initData(data);
            },
          });
        }
      },
      initData(data) {
        data = data[0];
        this.phone = data.YXDHPZ_LXTEL;
        this.email = data.YXDHPZ_EMAIL;
      },
    },
  };
</script>

<style scoped lang="less">
  .footer {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 300px 20px 300px;
    height: 360px;
    background: #1F4B8D;
    color: #FFF;

    a {
      text-decoration: none !important;
      color: #FFF;

      &:hover {
        color: #FE8811;
      }
    }

    span.pointer {
      &:hover {
        color: #FE8811;
      }
    }

    .left {
      width: 500px;
      height: calc(100% - 20px);
      margin-right: 100px;

      h4 {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 1px;

        &+p {
          font-size: 14px;
        }
      }

      .divider {
        width: 500px;
        height: 2px;
        margin: 20px 0;
        background: #FE8811;
      }

      p {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-right: 20px;
        font-weight: 300;
        text-align: left;

        &.phone {
          display: none;
        }

        .iconfont {
          width: 20px;
          margin-right: 10px;

          &.icon-telephone,
          &.icon-address {
            font-size: 18px;
          }
        }

        span {
          margin-right: 20px;
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      width: calc(100% - 600px);
      height: calc(100% - 20px);

      p {
        width: 100%;
        height: fit-content;
        margin-bottom: 40px;
        font-size: 22px;
        font-weight: bold;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 80px;

        .el-image {
          width: 125px;
          height: 125px;
          margin-bottom: 36px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .bottom {
      width: 100%;
      height: 20px;
      text-align: center;

      a {
        margin-left: 20px;
      }

      span.pointer {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #FFF;
      }
    }
  }

  // 移动端样式
  @media only screen and (max-width: 1023px) {
    .footer {
      padding: 30px;
      height: unset;

      .left {
        width: 100%;
        height: calc(100% - 20px);
        margin-right: 0;

        h4 {
          font-size: 22px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 3px;

          &+p {
            font-size: 17px;
          }
        }

        .divider {
          width: 100%;
          height: 2px;
          margin: 20px 0;
          background: #FE8811;
        }

        p {
          font-size: 20px;

          &.pc {
            display: none;
          }

          &.phone {
            display: inline-flex;
          }

          .iconfont {
            margin-right: 12px;
          }
        }
      }

      .right {
        display: none;
      }

      .bottom {
        margin-top: 50px;
        height: unset;

        span.pointer.map {
          display: none;
        }
      }
    }
  }
</style>
