import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import BaiduMap from 'vue-baidu-map';
import './util/rem';
import AOS from 'aos';
import '../node_modules/aos/dist/aos.css';
import './assets/font/iconfont.css';
import {
  serveUrl,
  myAxios
} from './assets/request.js';
import bus from './util/bus.js';
import scroll from 'vue-seamless-scroll';
// element ui 引入
import {
  Button,
  Dialog,
  Form,
  Radio,
  RadioGroup,
  Input,
  FormItem,
  Upload,
  Message,
  MessageBox,
  Carousel,
  CarouselItem,
  Image,
  Icon
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Input)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(AOS);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(BaiduMap, {
  ak: 'rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR'
});
Vue.use(scroll);
Vue.config.productionTip = false;
Vue.prototype.serveUrl = serveUrl;
Vue.prototype.myAxios = myAxios;
Vue.prototype.bus = bus;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
AOS.init({
  once: 'true'
});
