<template>
  <div class="cnt">
    <p class="title">隐私政策</p>
    <p><strong>更新日期：2021年10月26日</strong></p>
    <p class="mt30">
      <strong>江苏提米智能科技有限公司</strong>（以下简称“提米”或“我们”），十分尊重您的个人信息和数据，并会尽全力保护您的个人信息和数据。本政策适用于提米网站或APP收集和存储的用户信息和数据(包括提米新能源管理微平台APP以及其他链接至本链接的所有提米产品和服务)。请在向我们提交个人信息和数据前，阅读、了解和同意《提米隐私政策》。
    </p>
    <p>我们一贯重视用户的个人隐私和数据安全。我们希望尽可能以通俗、清晰的语言向用户描述我们如何收集、使用、存储和分享用户的相关信息。如果您对本政策内容有任何疑问、意见或建议，您可通过本隐私政策末尾提供的联系方式与我们联系。</p>
    <p>本隐私政策将帮助您了解以下内容：<br>
      (1) 我们收集的信息范围；<br>
      (2) 我们如何使用收集的信息；<br>
      (3) 我们如何使用Cookies或其他类似技术；<br>
      (4) 我们如何分享收集的信息；<br>
      (5) 我们如何处理未成年用户的信息；<br>
      (6) 用户如何管理自己的个人信息；<br>
      (7) 我们如何保障用户信息的安全；<br>
      (8) 隐私政策的更新；<br>
      (9) 如何联系我们。</p>
    <p class="mt30"><strong>【特别提示】</strong></P>
    <P>
      为了更好保护您的个人信息，请在使用提米的产品与服务前，仔细阅读并充分了解本政策。重点内容我们已采用粗体特别提示，希望您在阅读浏览时特别关注。一旦您使用或继续使用提米产品与服务，即表示您同意我们按照本政策处理您的相关信息。对于我们所收集的您的个人信息，我们亦用粗体的方式进行了标示，以此向您增强式提示。一旦您同意提供您的个人信息，我们将按本政策所述的目的和方式处理您的个人信息。我们使用您的个人信息的目的是为了实现提米的产品与服务相关的功能。您有权拒绝向我们提供个人信息，但是拒绝我们收集使用这些信息可能会影响您使用相关功能。
    </P>
    <p class="h2">一、我们收集的信息的范围</p>
    <p>为了实现更如用户预期的功能结果和优化我们产品与服务的性能，我们会自动从您访问应用程序的手机、平台电脑以及其他设备（以下简称“设备”）收集某些日志、行为、设备信息以及诊断信息等。这些信息包括：</p>
    <p>
      网络日志信息。您使用我们的产品和服务时，我们可能通过WEB日志或某些程序软件（如软件开发工具包、像素、脚本或其他跟踪机制自动收集某些信息并存储在我们的服务器日志中）。此类信息可能包括：对产品和服务的详细使用情况，如您应用程序的打开时间、使用时长、访问的页面地址等；设备事件信息，例如崩溃、系统活动等信息；IP地址；您所在的时区等；
    </p>
    <p>您的设备信息。为了能够正常向您提供各项功能，我们会收集您的相关设备信息，这些信息包括：<br>
      (1) 设备属性：如您设备的品牌、型号、操作系统、分辨率、屏幕尺寸、蓝牙设定、因特网连接状态、已安装的应用程序列表、硬件和软件版本、电池电量、信号强度、可用存储空间、浏览器类型、应用和文件名称及类型以及插件信息；<br>
      (2) 用户操作：有关用户在设备上执行的操作和行为的信息，例如窗口是设为当前还是后台运行；<br>
      (3) 设备序列号或其他设备标识符：如国际移动设备身份码IMEI、网络设备硬件地址（如MAC地址）、专有性标志符（如安卓设备中的Android ID）、广告标识符（如苹果设备中的IDFA）等设备序列号；<br>
      (4) 设备信号：您使用设备的网络状态、蓝牙状态、WIFI接入点或其他设备信号信息；<br>
      (5) 位置信息：我们可能会使用GPS、WiFi或其他技术方式收集和处理有关您所在位置的信息。</p>
    <p>
      对于比较敏感的用户信息，如用户IMEI号、GPS位置信息，我们在收集之前会征得您的同意。对于一些非敏感信息的收集，则可能是默认和自动的。您可以通过调整移动设备的“设置”选项限制我们从您的移动设备收集相关信息或使用特定权限。当您限制我们获取某项权限后，可能会影响某项功能的运行，导致我们可能无法继续为您提供相对应的服务，也不再处理您相应的信息。
    </p>
    <p class="h2">二、我们如何使用您的个人信息</p>
    <p>我们向您提供服务时，可能会使用您的个人信息用于身份验证、安全检测、客户服务、内容存档或备份等用途，确保我们向您提供的产品和服务的完整性和安全性；</p>
    <p>我们会通过您的个人信息以及其他数据了解您的使用习惯，并借助数据分析，识别服务的可接受程度、您的使用趋势等，以便能测试和创建新产品、功能与服务，提升服务水平，协助我们设计改进现有的服务、开发新的服务；</p>
    <p>我们可能会使用您的个人信息用于有关本产品和服务的客户调查、发送与本产品和服务有关的通知以及商业推广内容；</p>
    <p>我们可能会使用您的信息用于软件认证或管理软件升级；</p>
    <p>我们会使用您的信息开展与提米新能源管理微平台相关的内部分析与研究。</p>
    <p class="h2">三、我们如何使用Cookies或其他类似技术</p>
    <p class="h3">1、Cookies</p>
    <p>
      Cookies是用户访问网站时浏览器自动发送的一小段文本，通常包含标识符、站点名称以及一些号码和字符，一般每个网站会为用户生成的唯一Cookies,该Cookies数据会存储在您的本地设备。它会帮助我们记录用户访问我们的产品时的一些设置、习惯等，这样在用户以后访问我们的产品时会简化用户的登陆步骤，免除用户的重复设置，进而优化用户的使用体验。
    </p>
    <p>如果您在使用我们的产品时不想使用Cookies,您可以在设置中清除Cookies数据，但请注意清除Cookies数据可能会影响您对我们产品的使用体验。</p>
    <p class="h3">2、其他类似技术</p>
    <p>除了Cookies外，我们还会使用网站信标、像素标签、本地存储和软件开发工具包（“SDK”）来记住有关您的信息，以便为您提供更好的体验。像素是网页或电子邮件中的小图像。像素会收集有关您的设备的信息，并可以设置
      Cookies。本地存储允许将数据存储在本地设备上。SDK 是由我们的关联公司或第三方合作伙伴提供、可安装在我们的产品中的代码块。SDK
      可帮助我们了解您如何与我们的产品进行交互，并收集有关用于访问产品的设备和网络的某些信息。我们会使用这些技术记录您的设置偏好、提供账户认证与安全服务、优化服务功能与性能（如产品运行速度）、用于产品的分析与研究以及广告目的。</p>
    <p class="h2">四、我们如何分享和披露您的信息</p>
    <p>我们不会将您的个人信息出售给第三方，在获取您明确同意的情况下披露，可能会在以下情况下披露或分享您的个人数据：</p>
    <p>
      <strong>1、披露给我们特定员工、关联公司或附属公司的技术服务人员</strong>：我们可能会出于提供本产品与服务的目的需要将您的个人信息与其他信息与我们特定部门的员工、关联公司或附属公司的技术或其他工作人员共享。这些员工、关联公司或附属公司仅能存储其代表我们执行这些限定职务所需的信息。
    </p>
    <p>
      <strong>2、披露给第三方合作伙伴</strong>：我们的某些服务功能由我们的第三方合作伙伴（如承包商、代理商、广告合作伙伴、应用开发者等）合作提供，所以我们可能会与合作伙伴共享您的某些个人数据，以向你正常提供服务或者实现其他用户协议中所载明的目的。我们仅会出于特定、明确而合法的目的处理您的个人数据，并且只会向第三方披露提供服务所必要的数据。我们会要求第三方合作伙伴按照本隐私说明的要求，并采取符合行业标准的安全措施保证处理你的个人信息数据的安全性，不会将您的个人数据用于任何其他与本服务无关的用途。
    </p>
    <p>
      <strong>3、基于法律或维护您的重大利益的披露</strong>：在当适用的法律要求，或者回复司法机关传票、法院命令，或遵循其他执法机关的类似要求时，或为预防诈欺并保护我们自身、您、第三方或社会大众的财产或其他重大利益，我们酌情认为披露实属于紧迫、合理和必要时，我们可能会披露你的个人信息。
    </p>
    <p>
      <strong>4、企业并购、分立、破产等</strong>：如果发生合并、收购、破产或其他出售本公司全部或部分资产的情况，我们所拥有或控制的任何您的信息可能也是转移给第三方的财产之一，我们保留将您的信息及我们从本服务收集的其他信息转移给第三方的权利。
    </p>
    <p>我们可能会以特定的技术方式剥离您信息中的所有个人识别特征，与关联方、非关联方共享汇总的匿名化用户信息，用于与提供本产品与服务目的相关的分析和研究。</p>
    <p class="h2">五、我们如何对待未成年您的数据</p>
    <p>我们部分产品与服务的用户可能会包含18岁以下的未成年人，我们建议父母或监护人可以指导未满18岁的未成子女使用我们的产品与服务，未成年用户在使用我们的产品前应当就本隐私政策征求父母或监护人的意见。</p>
    <p class="h2">六、您如何管理您的个人信息</p>
    <p>依照我国《网络安全法》、《互联网用户个人信息保护规定》等法律、法规、规章的要求，以及参照其他国家、地区的通行做法，我们重视您对个人信息的自主控制权，并采取多重措施保障您对自己的个人信息可以行使如下权利：</p>
    <p class="h3">1. 访问您的个人信息：</p>
    <p>
      您有权访问您的个人信息，法律、法规规定的例外情况除外。您可以通过登陆您注册的账号，在账号设置中心查询、编辑您的个人信息。如果您无法通过上述方式管理该个人信息，您可以通过<strong>【timi@jiangsutimi.com】</strong>与我们取得联系。
    </p>
    <p class="h3">2. 更正您的个人信息：</p>
    <p>您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，如果在账号中心您无法自主操作，您可以通过<strong>【timi@jiangsutimi.com】</strong>与我们联系。</p>
    <p class="h3">3. 删除您的个人信息：</p>
    <p>在以下情形下，您可以通过<strong>【timi@jiangsutimi.com】</strong>向我们提出删除个人信息的请求：<br>
      (1) 我们处理您个人信息的行为违反法律、法规等的强制性规定；<br>
      (2) 我们收集、处理您的个人信息未征得您的同意；<br>
      (3) 我们收集、处理您的个人信息违反了与您的约定；<br>
      (4) 我们的某项产品、服务停止运营，不再向继续您提供；<br>
      (5) 您不再使用了我们的产品与服务，选择注销账号。</p>
    <p class="h3">4. 注销账户：</p>
    <p>
      您可以通过APP内的注销功能指引自助进行账号注销。若您无法注销账号，可通过<strong>【timi@jiangsutimi.com】</strong>与我们取得联系，我们一般会在15天内对您的反馈作出答复。我们承诺不会对您账号注销请求设置不合理的障碍或条件。请注意，一旦您注销提米新能源管理微平台移动账号，可能会无法使用提米新能源管理微平台的部分产品和服务，同时您先前积累的数据可能会不可逆地被永久删除，因此请您谨慎操作。
    </p>
    <p class="h3">5.撤回同意：</p>
    <p>
      您可以通过调整设备的有关权限设置，撤销之前出于特定目的授权我们收集、处理和披露我们控制的有关您的个人信息。根据您设置的不同，在撤销同意后，您可能无法继续使用我们的部分功能与服务。您撤销同意的操作，不会影响我们先前基于同意收集、处理您个人信息活动的合法性。
    </p>
    <p class="h2">七、我们如何维护您的信息安全</p>
    <p>我们会将您的个人信息与非个人信息上传、存储到中国大陆地区的数据中心。</p>
    <p>
      我们非常重视您用户信息的安全，并且我们会采取符合业内标准的安全防护措施来保护您的个人信息，部署多种技术方式和管理措施以防止数据遭到未经授权访问、修改、损坏或丢失。例如，我们会使用符合行业标准的加密技术确保您账号信息的完整性和机密性；我们会部署权限访问控制机制，确保只有特定的授权人员才可访问您的个人信息等。我们也会采用加密、匿名化等技术措施，保障用户信息存储、处理的安全。
    </p>
    <p>我们只会在实现本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。我们会尽力保护您的个人信息安全，但请注意，由于技术的复杂性任何安全措施都无法做到百分百安全无虞。</p>
    <p class="h2">八、本隐私政策的更新</p>
    <p>随着业务的发展，我们的隐私政策可能发生变更。对于本隐私政策的重大变更，我们会采用多种方式向您提供显著的更新提示。例如，在软件改版或升级时，以弹窗形式对您进行及时告知，您继续使用我们的服务即意味着您认可我们更新后的隐私政策。
    </p>
    <p class="h2">九、如何联系我们</p>
    <p>如果您对本隐私政策有任何疑问、意见或者投诉。请随时通过以下邮件联系我们：<strong>【timi@jiangsutimi.com】</strong>。一般情况下，我们会在15天内对您的邮件作出回复。</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    mounted() {
      sessionStorage.setItem("pageName", "PrivacyPolicy");
      this.bus.$emit('curPage', 0);
      this.bus.$emit("MENUSTATIC", true);
      sessionStorage.setItem('page', 0);
    }
  };
</script>

<style lang='less'>
  .cnt {
    margin: 150px 460px;
    color: #333;

    .title {
      margin-bottom: 15px;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }

    .h2 {
      margin-top: 30px;
      margin-bottom: 15px;
      font-size: 22px;
      font-weight: bold;
    }

    .h3 {
      margin-top: 20px;
      line-height: 40px;
      font-size: 18px;
      font-weight: bold;
    }

    .bolder {
      font-weight: bold;
    }

    .mt30 {
      margin-top: 32px;
    }

    p {
      line-height: 40px;
    }
  }

  // 移动端样式
  @media only screen and (max-width: 1023px) {
    .cnt {
      margin: 48px;
    }
  }
</style>
