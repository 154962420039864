import axios from 'axios';

export const serveUrl = 'http://192.168.1.63/TIMI';
// export const serveUrl = 'https://jstimi.com/TMOA';

export const myAxios = (options) => {
  const TK = sessionStorage.getItem('TK');
  if (TK) {
    myRequest(options, TK);
  } else {
    axios({
      method: 'post',
      url: serveUrl + '/T_INDEX/Login.aspx/GetLoginToken',
      data: {},
      headers: {
        'content-type': 'application/json; charset=utf-8',
        user: 'ADMIN',
        pwd: '12345'
      }
    }).then((response) => {
      sessionStorage.setItem('TK', response.data.TK);
      myRequest(options, response.data.TK);
    }).catch((error) => {
      console.log(error);
    });
  }
};

const myRequest = (options, TK) => {
  axios({
    method: options.method || 'GET',
    url: serveUrl + '/WIDGET/T_WEBSITE/WZService.ashx?action=' + options.action,
    params: options.data,
    headers: options.header || {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      TK: TK
    }
  }).then((response) => {
    if (Number(response.data.STATE) === 0) {
      if (options.success) {
        options.success(response.data.DATA);
      }
    } else {
      if (options.fail) {
        options.fail(response.data.MSG);
      }
    }
    if (options.complete) {
      options.complete(response.data);
    }
  }).catch((error) => {
    if (options.error) {
      options.error(error);
    }
    if (options.complete) {
      options.complete(error);
    }
  });
};
