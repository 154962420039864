import Vue from 'vue';
import VueRouter from 'vue-router';

// 引入组件
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Product from '../views/Product.vue';
import Plan from '../views/Plan.vue';
import New from '../views/New.vue';
import Support from '../views/Support.vue';
import JoinUs from '../views/JoinUs.vue';
import Sitemap from '../views/Sitemap.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import News from '../views/News.vue';

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [{
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    meta: {
      keepAlive: true // 需要被缓存
    }
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product
  },
  {
    path: '/Plan',
    name: 'Plan',
    component: Plan
  },
  {
    path: '/New',
    name: 'New',
    component: New
  },
  {
    path: '/Support',
    name: 'Support',
    component: Support
  },
  {
    path: '/JoinUs',
    name: 'JoinUs',
    component: JoinUs
  },
  {
    path: '/Sitemap',
    name: 'Sitemap',
    component: Sitemap
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/*',
    redirect: '/Home'
  }
];

var router = new VueRouter({
  mode: 'history',
  routes
});
export default router;
